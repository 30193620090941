<template>
  <a-card class="page-container">
    <a-row :gutter="24">
      <a-col :span="8">
        <a-card>
          <div slot="title">日历导航</div>
          <calendar-nav
            @select="handleCalendarChange"
          />
        </a-card>
      </a-col>
      <a-col :span="16">
        <a-spin :spinning="loading">        
          <div class="data-block">
            <div class="data-block__hd">导师日报进度</div>
            <div class="data-block__bd">
              <div>按时提交：{{ assistantSummary.onTimeSubmitDayList.length }}人</div>
              <div class="user-list">
                <div class="user" v-for="(item, index) in assistantSummary.onTimeSubmitDayList" :key="index">
                  <div class="user__avatar">
                    <a-avatar :src="item.avatar" :size="40" icon="user" />  
                  </div>
                  <div class="user__name">
                    {{ item.employeeName }}
                  </div>
                </div>
              </div>
              <div>提交延迟：{{ assistantSummary.delaySubmitDayList.length }}人</div>
              <div class="user-list">
                <div class="user" v-for="(item, index) in assistantSummary.delaySubmitDayList" :key="index">
                  <div class="user__avatar">
                    <a-avatar :src="item.avatar" :size="40" icon="user" />  
                  </div>
                  <div class="user__name">
                    {{ item.employeeName }}
                  </div>
                </div>
              </div>
              <div>未提交：{{ assistantSummary.unSubmitDayList.length }}人</div>
              <div class="user-list">
                <div class="user" v-for="(item, index) in assistantSummary.unSubmitDayList" :key="index">
                  <div class="user__avatar">
                    <a-avatar :src="item.avatar" :size="40" icon="user" />  
                  </div>
                  <div class="user__name">
                    {{ item.employeeName }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-spin>
      </a-col>
    </a-row>

    <a-row class="mt-24" :gutter="24">
      <a-col :span="8">
        <a-card class="collect-card">
          <a-button class="detail-btn" type="link" @click="jumpToPage">
            详情
          </a-button>
          <a-tabs v-model="activeTab" @change="handleTabsChange">
            <a-tab-pane tab="周汇总" :key="2"></a-tab-pane>
            <a-tab-pane tab="月汇总" :key="3"></a-tab-pane>
          </a-tabs>
          <a-table
            :loading="loading2"
            :columns="columns"
            :data-source="dealerSummary"
            :row-key="(record, index) => index"
            :pagination="false"
            :scroll="dealerSummary.length <= 5 ? {} : {y: 500}"
          >
            <div slot="student" slot-scope="text, record">
              <div>{{ record.userName || '--' }}</div>
              <div>{{ record.principalName || '--' }}</div>
              <div>{{ record.dealerName || '--' }}</div>
              <div></div>
            </div>
          </a-table>
        </a-card>
      </a-col>
      <a-col :span="16">
        <a-spin :spinning="loading2">
          <div class="data-block">
            <div class="data-block__hd">{{ timeText }}记录提交数据</div>
            <div class="data-block__bd">
              <div class="data-list">
                <div class="data-item">
                  <div class="data-item__label">按时提交</div>
                  <div class="data-item__value">{{ submitSummary.onTimeSubmitTotal }}</div>
                </div>
                <div class="data-item">
                  <div class="data-item__label">未提交</div>
                  <div class="data-item__value">{{ submitSummary.unSubmitTotal }}</div>
                </div>
                <div class="data-item">
                  <div class="data-item__label">延迟提交</div>
                  <div class="data-item__value">{{ submitSummary.delaySubmitTotal }}</div>
                </div>
              </div>
            </div>
          </div>
        </a-spin>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import CalendarNav from '@/components/actManage/CalendarNav'
import activityApi from '@/api/activity'

const columns = [
  {
    title: '学员',
    key: 'student',
    scopedSlots: { customRender: 'student' },
  },
  {
    title: '直播',
    dataIndex: 'liveTotal',
    key: 'liveTotal'
  },
  {
    title: '短视频',
    dataIndex: 'awemeTotal',
    key: 'awemeTotal'
  }
]

export default {
  name: 'actContent',
  components: {
    CalendarNav
  },
  data () {
    return {
      statisticsDate: this.$moment().format('YYYY-MM-DD'),
      loading: false,
      assistantSummary: {
        onTimeSubmitDayList: [],
        delaySubmitDayList: [],
        unSubmitDayList: []
      },
      activeTab: 2,
      loading2: false,
      columns,
      dealerSummary: [],
      submitSummary: {}
    }
  },
  created () {
    this.fetchAssistantSummaryLeader()
    this.fetchProductSummaryLeader()
  },
  computed: {
    timeText () {
      return this.activeTab === 2 ? '周' : '月'
    }
  },
  methods: {
    // 处理日历改变
    handleCalendarChange (date) {
      this.statisticsDate = date
      this.fetchAssistantSummaryLeader()
    },
    // 获取导师日报汇总(运营组长/客户经理)
    async fetchAssistantSummaryLeader () {
      this.loading = true
      try {
        const params = {
          activityId: this.$route.query.activityId,
          startTime: `${this.statisticsDate} 00:00:00`,
          endTime: `${this.statisticsDate} 23:59:59`
        }
        const { code, data, message } = await activityApi.fetchAssistantSummaryLeader(params)

        if (code === 200) {
          this.assistantSummary = data
        } else {
          this.$message.error(message);
        }

        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    // 处理周/月汇总改变
    handleTabsChange () {
      this.fetchProductSummaryLeader()
    },
    // 获取作品汇总(运营组长/客户经理)
    async fetchProductSummaryLeader () {
      this.loading2 = true
      try {
        const params = {
          activityId: this.$route.query.activityId,
          type: this.activeTab,
          statisticsDate: this.statisticsDate 
        }
        const { code, data, message } = await activityApi.fetchProductSummaryLeader(params)

        if (code === 200) {
          this.dealerSummary = data.dealerSummary
          this.submitSummary = data.submitSummary
        } else {
          this.$message.error(message)
        }
        this.loading2 = false
      } catch (e) {
        console.log(e)
        this.loading2 = false
      }
    },
    // 跳转页面
    jumpToPage () {
      this.$router.push({
        name: 'actManage:contentList',
        query: {
          activityId: this.$route.query.activityId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 0 !important;
}

.mt-24 {
  margin: 24px 0 0;
}

.data-block {
  &__hd {
    padding: 10px 0;
    font-size: 16px;
    font-weight: bold;
  }

  &__bd {
    padding: 10px 0;
  }
}

.user-list {
  display: flex;
  flex-wrap: nowrap;
  margin: 10px 0 20px;
  overflow-x: auto;
  overflow-y: hidden;
}
.user {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin: 0 0 0 20px;

  &__name {
    padding: 0 10px;
  }

  &:first-child {
    margin-left: 0;
  }
}

.collect-card {
  .detail-btn {
    position: absolute;
    top: 30px;
    right: 9px;
    z-index: 1;
  }
}

.data-list {
  display: flex;
  margin: 0 0 0 -16px;
}
.data-item {
  flex: 1;
  margin: 0 0 0 16px;
  padding: 40px 20px;
  border: 1px solid #e8e8e8;

  &__value {
    margin: 10px 0 0;
    font-size: 26px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
  }
}
</style>
