<template>
  <a-calendar
    :fullscreen="false"
    :header-render="headerRender"
    value-format="YYYY-MM-DD"
    @select="handleSelect"
  />
</template>

<script>
export default {
  name: 'CalendarNav',
  methods: {
    headerRender ({ value, type, onChange, onTypeChange }) {
      const start = 0
      const end = 12
      const monthOptions = []

      const current = value.clone()
      const localeData = value.localeData()
      const months = []
      for (let i = 0; i < 12; i++) {
        current.month(i)
        months.push(localeData.monthsShort(current))
      }

      for (let index = start; index < end; index++) {
        monthOptions.push(
          <a-select-option class="month-item" key={`${index}`}>
            {months[index]}
          </a-select-option>
        );
      }
      const month = value.month()

      const year = value.year()
      const options = []
      for (let i = year - 10; i < year + 10; i += 1) {
        options.push(
          <a-select-option class="year-item" key={i} value={i}>
            {i}
          </a-select-option>
        )
      }

      return (
        <div style={{ padding: '10px' }}>
          <a-row type="flex">
            <a-col>
              <a-select
                size="small"
                dropdownMatchSelectWidth={false}
                class="my-year-select"
                onChange={newYear => {
                  const now = value.clone().year(newYear);
                  onChange(now);
                }}
                value={String(year)}
              >
                {options}
              </a-select>
            </a-col>
            <a-col>
              <a-select
                size="small"
                dropdownMatchSelectWidth={false}
                value={String(month)}
                style="margin: 0 0 0 10px;"
                onChange={selectedMonth => {
                  const newValue = value.clone();
                  newValue.month(parseInt(selectedMonth, 10));
                  onChange(newValue);
                }}
              >
                {monthOptions}
              </a-select>
            </a-col>
          </a-row>
        </div>
      );
    },
    handleSelect (date) {
      this.$emit('select', date)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
